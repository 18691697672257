import { callApi, callDynamicApi } from '../utils/apiCaller';
import { toast } from 'react-toastify';


export const GET_EVENT_CATEGORIES = 'GET_EVENT_CATEGORIES';
export const GET_EVENT_FEATURED_CATEGORIES = 'GET_EVENT_FEATURED_CATEGORIES';
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_EVENTS_SORTED = 'GET_EVENTS_SORTED';
export const SUBMIT_AGENCY_CODE = 'SUBMIT_AGENCY_CODE';
export const SUBMIT_REQUEST_EVENTS = 'SUBMIT_REQUEST_EVENTS';
export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';

/*
 fetch User
*/
export const getEventCategories_ = (payload) => {
  return {
    type: GET_EVENT_CATEGORIES,
    payload,
  };
};

export const getEventCategories = (data) => {
  return (dispatch) => {
    callApi('list-categories/', 'GET', data).then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(getEventCategories_(response.data));
      }
    });
  };
};

export const getClearEventDetails = () => {
  return (dispatch) => {
    return dispatch({
      type: GET_EVENT_DETAILS,
      payload: {}

    })
  }
}

export const submitRequest = (data) => {
  return (dispatch) => {
    callDynamicApi('evtech_ticketrequests', 'POST', data).then((response) => {
      callApi('client-tickets-submission/', 'POST', {
        "to_email": data.emailaddress,
        "client_name": data.evtech_chaperonename,
        "event_name": data.cr676_event_name,
        "kids_tickets": data.evtech_actualchildren,
        "adult_tickets": data.evtech_numberofchaperones,
        "total_tickets": data.evtech_totaltickets,
        "evtech_totalticketvalue_base": data.cr676_event_price,
        "evtech_totalticketvalue": data.cr676_event_price * data.evtech_totaltickets,
        "agency": localStorage.getItem('name'),
        "business_unit": localStorage.getItem('eventCity')
      })
      if (response && response.code !== 400 && response.code !== 500) {
        toast.success('Ticket request has been successfully submitted to Agency');
        return dispatch({
          type: SUBMIT_REQUEST_EVENTS,
          payload: response,
        });
      }
    });
  };
};



export const submitRequestAgencyCode = (data) => {
  return (dispatch) => {
    callDynamicApi(`accounts?$filter=cr676_agency_code eq '${data.agency_code}' &$select=name, cr676_agency_code, cr676_location&$expand=owningbusinessunit`, 'GET').then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        if (response.data.value.length === 0) {
          toast.error('Sorry location is not found');
        }
        return dispatch({
          type: SUBMIT_AGENCY_CODE,
          payload: response,
        });
      }
    });
  };
};

export const getFeaturedCategories_ = (payload) => {
  return {
    type: GET_EVENT_FEATURED_CATEGORIES,
    payload,
  };
};
// evtech_events?$filter=evtech_displayonweb eq true
// 'evtech_events?$filter=${filter} ${eq} ${isTrue}//accounts?$filter=cr676_agency_code eq
export const getFeaturedCategories = () => {
  return (dispatch) => {
    // let qa = window.unescape(`evtech_events?$filter=cr676_display_on_experience_hub eq true and cr676_featured_on_experience_hub eq true and evtech_city eq '${localStorage.getItem('eventCity')}' &$expand=evtech_venueid($select=evtech_name)&$orderby=evtech_startdate`);
    // let qa = window.unescape(`evtech_events?$filter=cr676_display_on_experience_hub eq true and cr676_featured_on_experience_hub eq true and evtech_city eq '${localStorage.getItem('eventCity')}' &$expand=evtech_venueid($select=evtech_name)&$orderby=evtech_startdate`);
    let qa = window.unescape(`evtech_events?$filter=cr676_display_on_experience_hub eq true and cr676_featured_on_experience_hub eq true and owningbusinessunit/address1_city eq '${localStorage.getItem('eventCity')}' &$expand=evtech_venueid($select=evtech_name)&$orderby=evtech_startdate`);
    
    callDynamicApi(qa, 'GET').then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(getFeaturedCategories_(response.data));
      }
    });
  };
};

export const getAllEvents_ = (payload) => {
  return {
    type: GET_ALL_EVENTS,
    payload,
  };
};
// evtech_events?$filter=evtech_displayonweb eq true
// 'evtech_events?$filter=${filter} ${eq} ${isTrue}/
export const getAllEvents = () => {
  return (dispatch) => {
    callDynamicApi('evtech_events?$top=10', 'GET').then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(getAllEvents_(response.data));
      }
    });
  };
};

export const getAllEventsSorted_ = (payload) => {
  return {
    type: GET_EVENTS_SORTED,
    payload,
  };
};
// evtech_events?$filter=evtech_displayonweb eq true
// 'evtech_events?$filter=${filter} ${eq} ${isTrue}/
export const getAllEventsSorted = (rest, allCatgories) => {
  let string = '';
  for (let [key, data] of Object.entries(rest)) {
    if (key === "city") {
      if (data.id !== "") {
        string += ` ${data.operator} ${data.filter} ${data.eq} '${data.id}'`;
      }
    } else if (key === "search") {
      if (data.id !== "") {
        string += ` ${data.operator} ${data.filter}(evtech_name,'${data.id}') `
      }
    }
    else {
      string += ` ${data.operator} ${data.filter} ${data.eq} ${data.id}`;
    }
    if (data.operator2) {
      string += ` ${data.operator2} ${data.filter2} ${data.eq2} ${data.id2}`;
    }
    if (data.operator3) {
      string += ` ${data.operator3} ${data.filter3} ${data.eq3} ${data.id3}`;
    }
    if (data.operator4) {
      string += ` ${data.operator4} ${data.filter4} ${data.eq4} ${data.id4}`;
    }
  }
  let categoryQuery = ''
  allCatgories && allCatgories.map((iteam, index) => {
    categoryQuery += `${index === 0 ? '' : 'or'} evtech_eventcategory eq ${iteam.value}`;
    return true
  })
  if (categoryQuery && categoryQuery !== '')
    string += ` and (${categoryQuery})`

  return (dispatch) => {
    // let qa = window.unescape(`evtech_events?$filter=${filter} ${eq} ${id}`)
    let qa = window.unescape(`evtech_events?$filter=${string} &$expand=evtech_venueid($select=evtech_name)&$orderby=evtech_startdate`);
    callDynamicApi(qa, 'GET').then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(getAllEventsSorted_(response.data));
      }
    });
  };
};


export const getEventDetails_ = (payload) => {
  return {
    type: GET_EVENT_DETAILS,
    payload
  }
}

export const getEventDetails = (eventId) => {
  return (dispatch) => {
    callDynamicApi(`evtech_events(${eventId})?`, 'GET')
      .then((response) => {
        if (response && response.code !== 400 && response !== 500) {
          return dispatch(getEventDetails_(response.data))
        }
      })
  }
}
